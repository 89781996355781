/*
 * DashboardPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file DashboardPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import DashboardForm from './DashboardForm';
import { useDispatch, useSelector } from 'react-redux';
import { getClientEntitlement } from '../../../Store/Entitlements';
import { fetchFeedAction } from '../../../Store/Dashboard';
import { downloadFileRecord } from '../../../Store/FileRecords';
import { getSafeSettings } from '../../../Store/Settings';
import DownloadPageForm from './DownloadPageForm';
import { IEntitlementStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import {
  getDashboardState,
  IDashboardState
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import { IMappedFeed } from '@abstract/abstractwebcommon-shared/interfaces/feed';
import { useParams } from 'react-router-dom';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

export enum ProductCardClassName {
  Paused = 'date-text-paused',
  Active = 'date-text-active',
  Expired = 'date-text-expired'
}

/**
 * @interface IDashboardPageProperties
 */
interface IDashboardPageProperties {
  userUUID: string /**< user UUID. */;
}

export const DashboardPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const entitlementsState: IEntitlementStateSelector = useSelector(
    (state: IStateSelectors) => state.entitlements
  );
  const clientData = entitlementsState.clientData;
  const clientEntitlementsIsFetching = entitlementsState.clientEntitlementsIsFetching;
  const dashboardState: IDashboardState = useSelector(getDashboardState);
  const feedsData: IMappedFeed = dashboardState.feedsData;
  const [isDownloadPage, setIsDownloadPage] = useState<boolean>(false);
  const [fileRecordToDownload, setFileRecordToDownload] = useState<any>(null);
  const parameters: IDashboardPageProperties =
    useParams<IDashboardPageProperties>(); /**< Route parameters. */
  const userUUID: string = parameters.userUUID ?? LocalStorage.getXUserUUID(); /**< UserUUID */

  /// fetch client data and feeds
  useEffect(() => {
    dispatch(getClientEntitlement(userUUID));
    dispatch(fetchFeedAction());
    dispatch(getSafeSettings());
  }, [dispatch]);

  const handleDownloadClick = (file: any): void => {
    setIsDownloadPage(true);
    setFileRecordToDownload(file);
  };

  const downloadFile = (file: any): void => {
    dispatch(
      downloadFileRecord({
        fileRecordUUID: file.fileRecord.fileRecordUUID,
        versionID: file.versionID
      })
    );
  };

  const returnToFiles = (): void => {
    setIsDownloadPage(false);
  };

  useEffect(() => {
    window.onpopstate = () => {
      setIsDownloadPage(false);
    };
  }, [isDownloadPage]);

  if (isDownloadPage) {
    return (
      <DownloadPageForm
        fileRecord={fileRecordToDownload}
        downloadFile={downloadFile}
        returnToFiles={returnToFiles}
      />
    );
  } else {
    return (
      <DashboardForm
        handleDownloadClick={handleDownloadClick}
        isLoading={clientEntitlementsIsFetching}
        getClientEntitlement={getClientEntitlement}
        clientData={clientData}
        feedsData={feedsData}
        userUUID={userUUID}
      />
    );
  }
};
