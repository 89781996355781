/*
 * DownloadPageForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by etiennedaherinsta, 2022
 *
 * @file DownloadPageForm.tsx
 * @author etiennedaherinsta
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';
import { IFileRecordVersion } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecordVersion';

import './DownloadPageForm.css';

/**
 * Defines the interface for the React state 'selectedVersion'.
 * @interface IInternalIFileRecordVersion
 */
interface IInternalIFileRecordVersion extends IFileRecordVersion {
  displayName: string /** Defines the field to display in the version dropdown component. */;
}

interface IDownloadPageFormPropertiesInterface {
  fileRecord: IFileRecord;
  downloadFile: (file: any) => void;
  returnToFiles: () => void;
}

const DownloadPageForm = (properties: IDownloadPageFormPropertiesInterface): JSX.Element => {
  const { fileRecord, downloadFile, returnToFiles } = properties;

  const [selectedVersion, setSelectedVersion] = useState<IInternalIFileRecordVersion | null>(null);

  const footer: JSX.Element = (
    <div className="d-flex justify-content-between">
      <Button
        label="Return to files"
        icon="pi pi-arrow-left"
        className="p-button-danger p-ml-2"
        onClick={returnToFiles}
      />{' '}
      <Button
        label="Download"
        icon="pi pi-download"
        className="p-button-success p-ml-2"
        onClick={() => downloadFile(selectedVersion)}
      />
    </div>
  );

  // We should scroll this page to top once it is accessed.
  // The DashboardPage component renders this component along with the DashboardForm component during the React hydration step. This means that this component is rendered as soon as the DashboardPage is accessed.
  // Clicking on a row in the files table (which may require scrolling down the page depending on how much content the DashboardPage component loads) sets a useState hook to "true",
  // causing the DownloadPageForm component to be displayed at the same scroll position as the DashboardPage component.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedVersion == null) {
      const activeFileRecordVersion = fileRecord.fileRecordVersion.find(
        (fileRecordVersion: IFileRecordVersion) => fileRecordVersion.isActive
      );

      if (activeFileRecordVersion) {
        setSelectedVersion({
          ...activeFileRecordVersion,
          displayName: activeFileRecordVersion.versionName || activeFileRecordVersion.name // Fallback in case "versionName" is empty.
        });
      }
    }
  }, [selectedVersion]);

  if (selectedVersion == null && fileRecord.versionable) {
    return <></>;
  }

  return (
    <div className="download-info card-center">
      <Card className="p-mt-5 w-100 test" footer={footer}>
        {fileRecord.versionable && (
          <div className="d-flex flex-column">
            <Dropdown
              optionLabel="displayName"
              dataKey="displayName"
              value={selectedVersion}
              options={[...fileRecord.fileRecordVersion]
                .map((fileRecordVersion: IFileRecordVersion) => ({
                  ...fileRecordVersion,
                  displayName: fileRecordVersion.versionName || fileRecordVersion.name // Fallback in case "versionName" is empty.
                }))
                // Sorting by created dates.
                .sort((firstRecord: IFileRecordVersion, secondRecord: IFileRecordVersion) => {
                  const firstDate: number = new Date(firstRecord.created).getTime();
                  const secondDate: number = new Date(secondRecord.created).getTime();
                  return secondDate - firstDate;
                })
                // Sorting by the active record.
                .sort(
                  (firstRecord: IFileRecordVersion, secondRecord: IFileRecordVersion) =>
                    (secondRecord.isActive ? 1 : 0) - (firstRecord.isActive ? 1 : 0)
                )}
              onChange={(event: DropdownChangeEvent) => setSelectedVersion(event.target.value)}
              className="mb-4 version-dropdown-container"
            />
          </div>
        )}

        <div className="headerContainer">
          {fileRecord.icon && <img src={fileRecord.icon} className="fixed-img" />}

          <div className={`header justify-content-center ${fileRecord.icon ? 'left-margin' : ''}`}>
            <div className="d-flex .flex-column p-card-title">
              <span className="align-end">{fileRecord.name}</span>
            </div>
            <div className="p-card-subtitle">{fileRecord.website}</div>
          </div>
        </div>
        <p
          className="p-m-0"
          style={{ lineHeight: '1.5' }}
          dangerouslySetInnerHTML={{
            __html: fileRecord.description
          }}></p>

        {selectedVersion != null && selectedVersion.description != null && (
          <p
            className="p-m-0"
            style={{ lineHeight: '1.5' }}
            dangerouslySetInnerHTML={{
              __html: selectedVersion.description
            }}></p>
        )}
      </Card>
    </div>
  );
};

export default DownloadPageForm;
